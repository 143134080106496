<h2 mat-dialog-title>Create Channel</h2>
<form
  [formGroup]="formGroup"
  id="channel_create_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  *ngIf="errorBag$ | async as errors"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Channel Name</mat-label>
        <input matInput type="text" formControlName="name" required autocomplete="disabled" />
        <mat-error>{{ errors.get('name') }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Channel Code</mat-label>
        <span matPrefix>/&nbsp;</span>
        <input matInput type="text" formControlName="code" required autocomplete="disabled" />
        <mat-error>{{ errors.get('code') }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Currency</mat-label>
        <input
          type="text"
          matInput
          placeholder="Select a currency"
          formControlName="currency"
          [matAutocomplete]="currencyAuto"
          required
        />
        <mat-autocomplete #currencyAuto="matAutocomplete">
          <mat-option
            *ngFor="let option of currencyOptionsFiltered$ | async; trackBy: trackById"
            [value]="option.id"
          >
            {{ option.name }} ({{ option.id }})
          </mat-option>
        </mat-autocomplete>
        <mat-error>{{ errors.get('currency') }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Default Locale</mat-label>
        <input
          type="text"
          matInput
          placeholder="Select a locale"
          formControlName="defaultLocale"
          [matAutocomplete]="localeAuto"
          required
        />
        <mat-autocomplete #localeAuto="matAutocomplete">
          <mat-option
            *ngFor="let option of localeOptionsFiltered$ | async; trackBy: trackById"
            [value]="option.id"
          >
            {{ option.name }} ({{ option.id }})
          </mat-option>
        </mat-autocomplete>
        <mat-error>{{ errors.get('defaultLocale') }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="countries$ | async as countries">
        <mat-label>Default Country</mat-label>
        <mat-select formControlName="defaultCountry">
          <mat-option *ngFor="let country of countries" [value]="country.code">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="taxCategories$ | async as taxCategories">
        <mat-label>Default Tax Category</mat-label>
        <mat-select formControlName="defaultTaxCategoryId">
          <mat-option *ngFor="let taxCategory of taxCategories" [value]="taxCategory.id">
            {{ taxCategory.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-slide-toggle formControlName="enabled">Enable Channel</mat-slide-toggle>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="channel_create_form">Create</button>
</div>
