import { NgModule } from '@angular/core';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { ChannelCreateDialogComponent } from './components/channel-create-dialog/channel-create-dialog.component';
import { ChannelUpdateDialogComponent } from './components/channel-update-dialog/channel-update-dialog.component';
import { ChannelDialogService } from './services/channel-dialog.service';

@NgModule({
  imports: [EcommerceAdminCoreModule],
  declarations: [ChannelCreateDialogComponent, ChannelUpdateDialogComponent],
  exports: [],
  providers: [ChannelDialogService],
})
export class ChannelSharedModule {}
