<h2 mat-dialog-title>Update Channel</h2>
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  id="update_channel"
>
  <x-form-panel>
    <mat-form-field>
      <mat-label>Channel Name</mat-label>
      <input matInput type="text" formControlName="name" required autocomplete="disabled" />
    </mat-form-field>

    <x-locale-select label="Default Locale" formControlName="defaultLocale"></x-locale-select>

    <mat-form-field>
      <mat-label>Default Warehouse Id</mat-label>
      <mat-select formControlName="defaultWarehouseId">
        <mat-option *ngFor="let warehouse of warehouses$ | async" [value]="warehouse.id">
          {{ warehouse.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Currency</mat-label>
      <input
        type="text"
        matInput
        placeholder="Select a currency"
        formControlName="currency"
        [matAutocomplete]="currencyAuto"
        required
      />
      <mat-autocomplete #currencyAuto="matAutocomplete">
        <mat-option *ngFor="let option of currencyOptionsFiltered$ | async" [value]="option.id">
          {{ option.name }} ({{ option.id }})
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-slide-toggle formControlName="enabled">Enable Channel</mat-slide-toggle>
  </x-form-panel>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="update_channel" [disabled]="disabled">
    Update
  </button>
</div>
