import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ChannelCreateDialogComponent,
  ChannelCreateDialogData,
  ChannelCreateDialogResult,
} from '../components/channel-create-dialog/channel-create-dialog.component';
import {
  ChannelUpdateDialogComponent,
  ChannelUpdateDialogData,
  ChannelUpdateDialogResult,
} from '../components/channel-update-dialog/channel-update-dialog.component';

@Injectable({ providedIn: 'root' })
export class ChannelDialogService {
  constructor(private dialogs: MatDialog) {}

  openChannelCreateDialog() {
    return this.dialogs.open<
      ChannelCreateDialogComponent,
      ChannelCreateDialogData,
      ChannelCreateDialogResult
    >(ChannelCreateDialogComponent);
  }

  openChannelUpdateDialog(id: number) {
    return this.dialogs.open<
      ChannelUpdateDialogComponent,
      ChannelUpdateDialogData,
      ChannelUpdateDialogResult
    >(ChannelUpdateDialogComponent, {
      width: '500px',
      data: { id },
    });
  }
}
