import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormHelperService } from '@x/common/form';
import { LocaleService } from '@x/common/locale';
import { ChannelService, CurrencyItem, CurrencyService } from '@x/ecommerce/domain-client';
import { Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

export type ChannelUpdateDialogData = { id: number };
export type ChannelUpdateDialogResult = { id: number };

@Component({
  selector: 'x-channel-create-dialog',
  templateUrl: 'channel-update-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelUpdateDialogComponent implements OnInit {
  private _destroy$ = new Subject<void>();

  currencyControl = new UntypedFormControl(null);

  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(this.data.id),
    name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
    defaultLocale: new UntypedFormControl(null),
    defaultWarehouseId: new UntypedFormControl(null),
    currency: this.currencyControl,
    enabled: new UntypedFormControl(null),
  });

  currencyOptionsFiltered$: Observable<CurrencyItem[]> = this.currencyControl.valueChanges.pipe(
    takeUntil(this._destroy$),
    startWith(null),
    switchMap((text) => {
      if (!text) return this.currencyService.list();
      return this.currencyService.search(text);
    }),
  );

  warehouses$ = this.channelService
    .fetchDetail(this.data.id)
    .pipe(map((channel) => channel.warehouses));

  get disabled(): boolean {
    return (
      (this.formGroup.invalid && this.formGroup.touched) ||
      (this.formGroup.valid && this.formGroup.untouched)
    );
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ChannelUpdateDialogData,
    public dialog: MatDialogRef<ChannelUpdateDialogComponent>,
    private currencyService: CurrencyService,
    private localeService: LocaleService,
    private formHelper: FormHelperService,
    private channelService: ChannelService,
  ) {}

  ngOnInit() {
    this.channelService
      .fetchDetail(this.data.id)
      .pipe(
        takeUntil(this._destroy$),
        tap((channel) => {
          this.formGroup.patchValue(channel, { emitEvent: false });
          this.formGroup.markAsUntouched();
        }),
      )
      .subscribe();
  }

  submit() {
    const { invalid, value } = this.formGroup;

    this.formGroup.updateValueAndValidity();

    if (invalid) return;

    this.channelService
      .update(value)
      .pipe(
        takeUntil(this._destroy$),
        tap(({ id }) => this.dialog.close({ id })),
      )
      .subscribe();
  }
}
