import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormErrorBag, FormHelperService } from '@x/common/form';
import { LocaleItem, LocaleService } from '@x/common/locale';
import {
  ChannelService,
  CurrencyItem,
  CurrencyService,
  TaxCategoryService,
} from '@x/ecommerce/domain-client';
import { CountryService } from '@x/geocode/client';
import { Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

export interface ChannelCreateDialogData {}

export interface ChannelCreateDialogResult {
  deferTaxonCreate?: boolean;
  data: { id: number; name: string };
}

@Component({
  selector: 'x-channel-create-dialog',
  templateUrl: 'channel-create-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelCreateDialogComponent implements OnInit {
  currencyControl = new UntypedFormControl(null, [Validators.required]);
  localeControl = new UntypedFormControl(null, [Validators.required]);
  countryControl = new UntypedFormControl(null, [Validators.required]);
  taxCategoryControl = new UntypedFormControl(null, [Validators.required]);

  countries$ = this.countryService.fetchCountrySearch();
  taxCategories$ = this.taxCategoryService.getAllTaxCategories();

  formGroup = new UntypedFormGroup({
    code: new UntypedFormControl(null, [Validators.required, Validators.maxLength(2)]),
    name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
    defaultLocale: this.localeControl,
    defaultCountry: this.countryControl,
    defaultTaxCategoryId: this.taxCategoryControl,
    currency: this.currencyControl,
    enabled: new UntypedFormControl(true),
  });

  currencyOptionsFiltered$: Observable<CurrencyItem[]>;
  localeOptionsFiltered$: Observable<LocaleItem[]>;

  errorBag$: Observable<FormErrorBag>;

  constructor(
    public dialog: MatDialogRef<ChannelCreateDialogComponent, ChannelCreateDialogResult>,
    private currencyService: CurrencyService,
    private localeService: LocaleService,
    private formHelper: FormHelperService,
    private channelService: ChannelService,
    private countryService: CountryService,
    private taxCategoryService: TaxCategoryService,
  ) {}

  ngOnInit() {
    this.errorBag$ = this.formHelper.watchErrors(this.formGroup);

    this.currencyOptionsFiltered$ = this.currencyControl.valueChanges.pipe(
      startWith(null),
      switchMap((text) => {
        if (!text) return this.currencyService.list();
        return this.currencyService.search(text);
      }),
    );
    this.localeOptionsFiltered$ = this.localeControl.valueChanges.pipe(
      startWith(null),
      switchMap((text) => {
        if (!text) return this.localeService.list();
        return this.localeService.search(text);
      }),
    );
  }

  submit() {
    // https://openexchangerates.org/api/currencies.json

    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) {
      console.log(`Form invalid`);
      return;
    }

    this.channelService.create(this.formGroup.value).subscribe((channel) => {
      this.dialog.close({ data: channel });
    });
  }

  trackById(index: number, item: any) {
    return item.id;
  }
}
